import React from "react"
import styled from "styled-components"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import options from "../utils/richtext-rendering-options"

import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../components/PageHeader"
import Prose from "../components/Prose"
const StyledHonoraires = styled.div`
  /* ... */

  .left-align {
    columns: 300px 2;
    column-gap: 60px;
    margin-bottom: 4em;
  }
  p,
  li {
    line-height: 1.6;
    font-weight: 400;
    color: var(--gray2);
    font-size: 14px;
    text-align: justify;
  }
`

export default function Honoraires() {
  const data = useStaticQuery(graphql`
    query HonorairesQuery {
      contentfulHonoraires {
        contenu {
          raw
        }
      }
    }
  `)
  const text = renderRichText(data.contentfulHonoraires.contenu, options)

  return (
    <StyledHonoraires>
      <div className="container">
        <PageHeader title="Honoraires" />
        <div className="left-align">
          <Prose>
            <article>{text}</article>
          </Prose>
        </div>
      </div>
    </StyledHonoraires>
  )
}
